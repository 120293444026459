.amd-bs5 {
	.heroExpressive {
		.hero-content.casestudy-content {
			@include media-breakpoint-up(xl) {
				@include generate-spacing(padding, 70, 'x');
			}
			@include media-breakpoint-down(sm) {
				@include generate-spacing(padding, 50, 'y');
			}
		}
		
	}
}
