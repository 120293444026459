.amd-bs5 {
    .gamingBenchmarks {
        .gaming-benchmarks-interface {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            gap: map-get($spacers, 40);
            @include generate-spacing(padding, 60, 'b');
            select.form-select {
                width: auto;
            }
        }
        .gaming-benchmarks-metrics {
            @include subheadline;
            display: flex;
            justify-content: center;
            gap: map-get($spacers, 40);
            width: 100%;
            a {
                color: inherit !important;
                text-decoration: none;
                opacity: .5;
                &.active {
                    opacity: 1;
                    text-decoration: none;
                    &:after {
                        transform: scaleX(1);
                        border-top: 0;
                        border-left: 0;
                        border-right: 0;
                        outline: none;
                        
                    }
                }
                &:after {
                    display: block;
                    content: '';
                    border: 0;
                    border-bottom: 2px solid $corporate-black;
                    transform: scaleX(0);
                    transition: transform 250ms ease-in-out;
                    padding-top: map-get($spacers, 25);
                    margin: 0;
                    width: 100%;
                }
            }
        }
        .gaming-benchmarks-chart {
            position: relative;
        }
        .gaming-benchmarks-config {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include generate-spacing(padding, 60, 't');
            dl {
                display: flex;
                flex-wrap: wrap;
                gap: 1%;
                dt {
                    flex-basis: 49.5%;
                    text-align: right;
                }
            }
        }
        &.text-light {
            .gaming-benchmarks-metrics a:after {
                border-bottom: 2px solid $corporate-white;
            }
        }
    }
}