.amd-bs5 {
	.support-article {
		&>.container {
			padding-top: 0;
			padding-bottom: 0;
		}
		display: flex;
		.side-nav-container {
			position: sticky;
			top: map-get($spacers, 55);
			.vertical-anchor-bar {
				padding-top: 0;
			}
		}
	}
	@include media-breakpoint-down(xl) {
		.support-article {
			flex-direction: column;
			&>.container {
				padding: 0;
			}
			.center-container, .side-nav {
				width: 100%;
			}
			.side-nav {
				position: sticky;
				top: 0;
				z-index: 1025;
				padding: 0;
				order: -1;
			}
			.center-container {
				height: 100%;
			}
			.support-row {
				margin: 0;
			}
			.article-container, .center-container {
				padding: 0 map-get($spacers, 45);
			}
		}
	}
}
