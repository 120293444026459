.amd-bs5 {
	.cmp-relatedblogs {
		@include generate-spacing(padding, 60, 'b');
		a {
			text-decoration: none;
		}
		.cmp-relatedblogs__blog {
			@include generate-spacing(padding, 40, 'xy');
			@include generate-spacing(margin, 45, 'b');
			@include border-radius(5px);
			box-shadow: $skim;
			&:hover {
				box-shadow: $raised;
				img.cmp-image__image {
	                @include opacity(1);
	                @include box-transition(none);
	            }
			}
			.cmp-relatedblogs__info{
				 @include generate-spacing(padding, 40, 'e');
				.cmp-relatedblogs__byline {
					height: map-get($spacers, 45);
					$imageSize: map-get($spacers, 45);
					display: flex;
					align-items: center;
					@include generate-spacing(margin, 40, 'b');
				
				    .cmp-relatedblogs__authorimage {
				        float: left;
				        
					    img {
					        width: $imageSize; 
					        height: $imageSize; 
					        border-radius: calc($imageSize / 2);
					        object-fit: cover;
					    }
				    }
				    
				    .cmp-relatedblogs__authorname {
						@include medium();
					    @include generate-spacing(margin, 0, 'b');
					    @include generate-spacing(padding, 40, 's');
						color: $neutral-60;
						
						
					}
				}
				.cmp-relatedblogs__title {
					color: $corporate-black;
				}
			}
			
			.cmp-relatedblogs__image {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				
				img {
					display: block; 
				    max-width: 100%;
					max-height: 100%;
				    width: auto;
				    height: auto;
				}
				
			}
		}
	}
}
