.amd-bs5 {
	.side-nav-template {
		display: flex;
		.side-nav-left, .side-nav-right {
			width: 18.75rem;
			flex-shrink: 0;
		}
		.side-nav-left {
			background-color: $neutral-90;
		}
		.side-nav-center {
			flex-grow: 1;
			min-width: 0;
			position: relative;
			.footnotes {
				.footnote-container {
					background-color: unset;
					@include generate-spacing(padding, 45, 'b');
					@include media-breakpoint-up(xs) {
						@include generate-spacing(padding, 45, 'x');
					}
					@include media-breakpoint-up(xxl) {
						@include generate-spacing(padding, 70, 'x');
					}
				}
				--primary: black;
				--secondary: white;
				details {
					background-color: white;
					color: black;
					border: 1px solid #e1e1e2;
					border-radius: calc(.25rem - 1px);

					& > .container {
						padding-left: var(--bs-gutter-x, 1.0625rem);
						padding-right: var(--bs-gutter-x, 1.0625rem);
						max-width: 960px;
					}
					&> summary {
						color: black;
						border-bottom: 1px solid #e1e1e2;
						col {
							display: flex;
							justify-content: space-between;
						}
						&:hover {
							background-color: $neutral-20;
						}
						&>.container {
							padding-left: var(--bs-gutter-x,1.0625rem);
							padding-right: var(--bs-gutter-x,1.0625rem);
						}
					}
				}
			}
		}
		.side-nav-container {
			position: sticky;
			top: 0;
			/*overflow-y: auto;
			height: 100vh;*/
		}

		.side-nav-left.hidden, .side-nav-right.hidden {
			display: none;
		}

		.side-nav-button-container {
			position: sticky;
			top: 0;
			display: flex;
			justify-content: space-between;
			z-index: 1000;
			height: 0;
		}

		.toggle-btn.show i {
			transform: rotate(180deg);
		}

		.side-nav-center .toggle-btn {
			border: none;
			background: $corporate-white;
			border-radius: 50%;
			width: map-get($spacers, 45);
			height: map-get($spacers, 45);
			display: flex;
			align-items: center;
			justify-content: center;
			@include generate-spacing(margin, 35, 't');
			@include generate-spacing(margin, 35, 'x');
		}

		#toggle-left-sidebar {
			left: 1rem;
		}

		#toggle-right-sidebar {
			right: 1rem;
		}
	}
	
	@include media-breakpoint-down(xxl) {
		.side-nav-center {
			.container-lg { 
				padding-left: 1.625rem;
				padding-right: 1.625rem;
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.side-nav-template {
			flex-direction: column;
			.side-nav-left, .side-nav-center, .side-nav-right {
				width: 100%;
			}
			.side-nav-center {
				order: 2;
			}
			.side-nav-left {
				position: sticky;
				top: 0;
				z-index: 1026;
				&.hidden {
					display: block !important;;
				}
			}
			.side-nav-right {
				position: sticky;
				top: 56px;
				z-index: 1025;
				&.hidden {
					display: block !important;;
				}
			}
			.side-nav-container {
				height: 100%;
			}

			.side-nav-button-container {
				display: none;
			}
		}
	}
}
