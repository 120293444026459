.amd-bs5 {
  .input-group.product-search {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  [data-component="coveo-browse-documentation"] {
      .input-group {
        border-radius: 2px;
        border: 1px solid #000;
        // height: 2.265rem;
        width: 100%;
        margin-bottom: 3.5rem;
        font-size: 0.875rem;
      }
      button {
        font-weight: 500;
        font-size: 16px;
        border-radius: 2px;
        padding: 0.625rem 2.125rem;
        border: 1px solid #000;
        background: white;
      }
      label {
        font-weight: 600;
        margin-bottom: 0.375rem;
      }
      select.form-select.col {
        margin-bottom: 1.625rem;
      }
  }
}