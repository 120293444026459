.amd-bs5 {
  .darkDataTable  {
    .btn {
    --primary: #{$corporate-white};
    --secondary: #{$corporate-black};
    }
    --bs-body-color: #{$corporate-white};
    --bs-body-bg: #{$corporate-black};
    color: var(--bs-body-color);
  }
  .product-specs-compare {
    @include media-breakpoint-down(sm) {
      display: none;
    }

      .btn {
      padding: .5rem;
    }
    .input-group {
      flex-wrap: nowrap;
      margin-bottom: 1rem;
    }
  }
  table.product-compare-table {
    >:not(caption)>*>* {
      background-color: transparent;

    }
    th {
      top: 0;
      position: sticky;
      background-color: var(--foreground, #{$corporate-black});
      color: var(--background, #{$corporate-white});
      height: 2rem;
    }
    tr {
      border-top-width: 1px;
      border-bottom-width: 1px;
      border-top-color: $corporate-black;
      border-bottom-color: $corporate-black;
    }
    background-color: var(--bs-table-bg);
  }

  input.compareSelector {
    border-radius: 2px;
    border: 1px solid #000;
    width: 100%;
    font-size: 0.875rem;
  }
  .compareShow {
    font-weight: 500;
    font-size: 16px;
    border-radius: 2px;
    padding: 0.625rem 2.125rem;
    border: 1px solid #000;
    background: white;
  }
  .cmp-product-specs-table .datatable {
    table {
      &.dl-content-nowrap tr th {
        white-space: nowrap;
      }
      tr th {
        white-space: normal;
        select {
          max-width: 8rem;
        }
      }
    }
  }
  .tooltip.tooltip-lighter {
    .tooltip-inner, .tooltip-arrow {
      background: $neutral-90 !important;
    }
  }
  .product-tooltip {
    padding-left: 5px;
  }
}