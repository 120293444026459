.amd-bs5 {
	.cmp-authorfragment {
		@include generate-spacing(padding, 45, 't');
		@include generate-spacing(padding, 60, 'b');
		p {
			@include generate-spacing(margin, 0, 'b');
		}
		.cmp-authorfragment__byline {
			height: map-get($spacers, 60);
			$imageSize: map-get($spacers, 60);
			display: flex;
			align-items: center;
			@include generate-spacing(margin, 35, 'b');
		
		    .cmp-authorfragment__image {
		        float: left;
		        
			    img {
			        width: $imageSize; 
			        height: $imageSize; 
			        border-radius: calc($imageSize / 2);
			        object-fit: cover;
			    }
		    }
		    
		    .cmp-authorfragment__info {
				@include generate-spacing(padding, 40, 's');
				
				.cmp-authorfragment__name {	
			        color: $corporate-black;
			    }
			
			    .cmp-authorfragment__by {
			        color: $neutral-60;
			        @include medium();
			        @include generate-spacing(margin, 0, 'b');
			    }
			}
		}
		
		 .cmp-authorfragment__bio {
	        color: $neutral-60;
	        @include generate-spacing(padding, 35, 'b');
	    }
	    
	    .cmp-authorfragment__link {
	        color: $access-link-dark;
	    }
	}
}
