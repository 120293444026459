.amd-bs5 {
	.support-details {
		.driver-expand-collapse {
			cursor: pointer;
		}
		ul.product-specs-categories {
			dl {
				grid-template: auto/clamp(100px, 50%, 390px) 1fr;
			}
			@include media-breakpoint-down(sm) {
				dl {
					grid-template-columns: 1fr;
					div:not(:last-child) {
						dd {
							margin-bottom: map-get($spacers, 50);
						}
					}
				}

				dt {
					margin-bottom: map-get($spacers, 25);
				}
			}
			
		}
	}
}
