.amd-bs5 {
	.product-detail-page {
		.cmp-imagethumbnailcarousel {
			.cmp-imagethumbnailcarousel__mainimage {
				height: auto;
				image {
					max-height: none;
				}
				.cmp-image {
					img {
						max-height: none;
					}
				}
			}
			.clkImageZoomBtn {
				bottom: 2rem;
			}
		}
		
		@include media-breakpoint-up(xl) {
			.anchorBar {
				.menu-container {
					background-image: linear-gradient(to right, $neutral-80, $neutral-90,  $neutral-80);
				}
			}
		}
		
	    .product-info {
	        ul {
	            padding: 0;
	            list-style-type: none;
	            margin-bottom: map-get($spacers, 45);
	            li {
					margin-bottom: map-get($spacers, 25);
					
				}
	        }
	    	img.logo {
	        	width: map-get($spacers, 75);
	        }
	        span {
				&.strike {
					text-decoration:line-through;
				}
				&.product-label {
					font-weight: 700;
					
				}
			}
			
			.product-distributors {
				margin-bottom: map-get($spacers, 45);
				.distributor-link {
					span {
						a {
							font-weight: 600;
					
						}
					}
				}
			}
	        
	        .distributor-modal {
		  		.modal-dialog {
					.modal-content {
						/*.border-radius(10px);*/
						overflow: hidden;
						.modal-header {
							background-color: $corporate-black;
		    				color: $corporate-white;
		    				.modal-title {
								margin-bottom: 0;
							}
						}
						.modal-body {
							@include media-breakpoint-up(md) {
								padding: map-get($spacers, 45);
								&.license-body {
									.license-description {
										display: -webkit-box;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 9;
										overflow: hidden;
										padding-bottom: 0;
										.license-row {
											margin-bottom: map-get($spacers, 50);
											.add-to-cart {
												margin-top: map-get($spacers, 30);
												margin-bottom: map-get($spacers, 30);
											}
										}
									}
								}
							}
							img {
								margin: 0;
								width: 100%;
							}
							
						}
					}
				}
			}
	    
		}	
	}
}
