.amd-bs5 {
	.infinity-hub {
		.code {
			code {
				white-space: pre-wrap;
				overflow-wrap: anywhere;
			}
		}
	}
}
