
.page-editable-press {
	.amd-bs5 {
		.press-image {
			@include generate-spacing(margin, 50, 'b');
		}
		.sharethis-inline-share-buttons-container {
			.sharethis-title {
				display: none;
				font-weight: bold;
				@include generate-spacing(margin, 30, 'b');
			}
			.sharethis-inline-share-buttons {
				@include generate-spacing(margin, 45, 'b');		
				.st-btn {
					@include generate-spacing(margin, 30, 'b');						
				}
				.st-btn[data-network='twitter'],
				.st-btn[data-network='facebook'],
				.st-btn[data-network='linkedin'],
				.st-btn[data-network='email'],
				.st-btn[data-network='sharethis'] {
				    display: inline-block !important;
				}	
			}				
		}		
		.article-container {
			.sharethis-inline-share-buttons-container {
				display: flex;
				justify-content: flex-end;
				width: 100%;				
				@include generate-spacing(margin, 40, 'b');				
				.sharethis-title {
					margin-bottom: 0;
					@include generate-spacing(margin, 35, 'e');
					line-height: 2em;
				}
				.sharethis-inline-share-buttons {					
					margin-bottom: 0;
					#st-12 {
						.st-btn {
							margin-bottom: 0;
						}
					}				
				}										
			}
		}
		.media-container {
			.divider {
				.divider.small {
				    padding-bottom: 0;
				    padding-top: 0;
				}
			}
		}
	}
}	
