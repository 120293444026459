.amd-bs5 {
	.product-distributors {
		.modal {
			.modal-dialog {
				max-width: $modal-md;
			}
			hr {
				margin: 0;
			}
			.cta {
				display: flex;
				align-items: center;
				.btn {
					margin: 0;
					width: 100%;
				}
			}
		}	
	}
}
