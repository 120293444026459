.amd-bs5 {

  button.expansion-toggle {
    &:hover {
      background-color: $corporate-black;
      color: $corporate-white;
    }
    border: solid 1px $corporate-black;
    border-radius: 2px;
    @include medium();
    background-color: $neutral-20;
    padding: map-get($spacers, 25) map-get($spacers, 30);
    margin: map-get($spacers, 45) 0rem;
  }
  ul.product-specs-categories {
	list-style: none;

    div.key-value-table {
      display: table;
    }
    div.key-value-row {
      display: table-row;
    }
    div.key-value-cell:first-child {
      font-weight: bold;
      padding-left: 0;
    }
    div.key-value-cell {
      display: table-cell;
      padding: 0 5px 5px ;
    }

    .accordion-button {
      border-top: 1px solid #e1e1e2 ($neutral-20);
      padding: map-get($spacers, 40) map-get($spacers, 40) map-get($spacers, 40) 0;
      &:hover {
        background-color: initial;
      }
      &:not(.collapsed) {
        border-bottom: none;
      }
    }

    dl {
      display: grid;
      grid-template: auto / 250px 1fr;
      &>div {
        display: contents;
      }
    }

    dt, dd {
      margin: map-get($spacers, 35) map-get($spacers, 40)  map-get($spacers, 40) 0;
    }

    dd p:last-child {
      margin-bottom: 0;
    }

    dt {
      font-weight: bold;
    }

    hr {
      color: $neutral-80;
      height: 1px;
      @include generate-spacing(margin, 45, 'y');
    }

    @include media-breakpoint-down(sm) {
      dl {
        grid-template-columns: 1fr;
        div:not(:last-child) {
          dd {
            margin-bottom: map-get($spacers, 50);
          }
        }
      }

      dt {
        margin-bottom: map-get($spacers, 25);
      }
    }
  }
}
