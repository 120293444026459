.amd-bs5 {
	.alveo-downloads {
		.btn.btn-downloads-narrow {
			margin-bottom: map-get($spacers, 30);
			a {
				padding: map-get($spacers, 30);
			}
		}
	}
}
